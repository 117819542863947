// @flow
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import Switch from 'react-switch'
import { Close } from '@material-ui/icons'

import * as v from '../../config/variables'
import { isController } from '../../lib/helper'
// import { Button as ButtonGeneric } from '../UI'

type Props = {
  // loading: boolean,
  onChange: Function,
  // onSubmit?: Function,
}

const FilterBox = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${v.gray100};
  border-radius: ${v.borderRadiusDefault};
  padding: 0.5rem 0.5rem;
  border: 1px solid ${v.gray400};

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Group = styled.div`
  display: flex;

  margin: 0.5rem 0;

  @media (min-width: 576px) {
    margin: 0;
  }
`

const SearchInput = styled.input`
  display: block;
  width: 100%;
  border: 1px solid ${v.gray400};
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  background-color: ${v.white};
  transition: box-shadow ease 0.2s;
  padding-right: 2.3rem;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }
`

// const SortInput = styled.select`
//   display: block;
//   font-size: 1rem;
//   cursor: pointer;
//   /* font-weight: 700; */
//   color: #444;
//   line-height: 1.3;
//   padding: 0.6em 1.4em 0.6em 0.8em;
//   width: 100%;
//   min-width: 185px;
//   max-width: 100%;
//   box-sizing: border-box;
//   margin: 0;
//   border: 1px solid ${v.grayLight};
//   border-radius: 3px;
//   appearance: none;
//   background-color: ${v.grayLighter};
//   background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAvElEQVRIie3WPQrCMBjG8X/Fwev0NgXHgqND0dFNlA528gCOuU2u42RdFEpI89VXCjXPlifDj0B4k6KnZ46sZlEznOFFwuvhourKG7A3+4l5AkfV6PuwNE+8E0YBNkBtliZ8FUYBXkDrhFWjL8BZGK1Vo5UT/uAnIfyLPmyb1lstgDvRUXgi7kWdcCIehHrhSDwYDYID8Sg0GPbg0WgUPIInoQBFyp+r6soDsAVa23D4GSyR/3uPM5zh5cFvU0hRnnzyNKcAAAAASUVORK5CYII=');
//   background-repeat: no-repeat, repeat;
//   background-position: right 0.7em top 50%, 0 0;
//   background-size: 1.2rem auto, 100%;

//   &::-ms-expand {
//     display: none;
//   }
//   &:hover {
//     border-color: #888;
//   }
//   &:focus {
//     border-color: #aaa;
//     outline: none;
//     box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
//   }
//   & option {
//     font-weight: normal;
//   }
// `

// const Button = styled(ButtonGeneric)`
//   /* padding: 0.3rem 1rem; */
//   margin-left: 1rem;
// `

const SearchGroup = styled(Group)`
  position: relative;
`

const SearchDeleteButton = styled(Close)`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  padding: 3px;
  transform: translateY(-50%);
  color: ${v.white};
  background-color: ${v.gray600};
  font-size: 1.5rem !important;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 0.5rem;
  cursor: pointer;
`

const ToggleGroup = styled(Group)`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  color: ${props => (props.active ? v.gray600 : v.gray500)};
  transition: color 0.3s ease;
  cursor: pointer;
`

const ListFilter = (props: Props) => {
  const { onChange } = props
  const [query, setQuery] = useState('')
  const [filterMyTasks, setFilterMyTasks] = useState(false)
  const [filterCompletedTasks, setFilterCompletedTasks] = useState(
    isController()
  )
  // const [sort, setSort] = useState('newest')

  // const handleSubmit = (value, e) => {
  //   onSubmit()
  //   setQuery(value)
  //   if (e) e.preventDefault()
  // }

  useEffect(() => {
    onChange({ query, filterMyTasks, filterCompletedTasks }) // sort
    // onSubmit()
  }, [query, filterMyTasks, filterCompletedTasks])

  return (
    // onSubmit={handleSubmit}
    <FilterBox>
      <SearchGroup>
        <SearchInput
          type="text"
          onChange={e => setQuery(e.target.value)}
          placeholder="Suchbegriff eingeben…"
          maxLength={50}
          value={query}
        />

        {query.length > 0 && (
          <SearchDeleteButton onClick={() => setQuery('')} />
        )}

        {/* <Button onClick={handleSubmit} type="submit" disabled={loading}>
          Suchen
        </Button> */}
      </SearchGroup>

      {!isController() && (
        <ToggleGroup>
          <Switch
            onChange={value => setFilterMyTasks(value)}
            checked={filterMyTasks}
            offColor={v.gray300}
            onColor={lighten(0.4, v.brandColor)}
            offHandleColor={v.gray500}
            onHandleColor={v.brandColor}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow="none" // 0 0 2px 3px ${v.brandColor}
            width={40}
            height={22}
            handleDiameter={27}
            boxShadow={`0 0 3px 0 ${v.gray400}`}
          />

          <Label
            active={filterMyTasks}
            onClick={() => setFilterMyTasks(!filterMyTasks)}
          >
            Meine Aufgaben
          </Label>
        </ToggleGroup>
      )}

      {isController() && (
        <ToggleGroup>
          <Switch
            onChange={value => setFilterCompletedTasks(value)}
            checked={filterCompletedTasks}
            offColor={v.gray300}
            onColor={lighten(0.4, v.brandColor)}
            offHandleColor={v.gray500}
            onHandleColor={v.brandColor}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow="none" // 0 0 2px 3px ${v.brandColor}
            width={40}
            height={22}
            handleDiameter={27}
            boxShadow={`0 0 3px 0 ${v.gray400}`}
          />

          <Label
            active={filterCompletedTasks}
            onClick={() => setFilterCompletedTasks(!filterCompletedTasks)}
          >
            Erledigte Aufgaben
          </Label>
        </ToggleGroup>
      )}

      {/* <Group>
        <SortInput
          onChange={e => {
            setSort(e.target.value)
          }}
          value={sort}
        >
          <option value="newest">Neueste zuerst</option>
          <option value="relevancy">Relevanz</option>
        </SortInput>
      </Group> */}
    </FilterBox>
  )
}

export default ListFilter
