// @flow
import React from 'react'
import { navigate } from 'gatsby' // , graphql, useStaticQuery
// import ReactMarkdown from 'react-markdown'

import { Container } from 'reactstrap'

import { ButtonLink } from '../components/UI'
import Layout, { SEO } from '../components/Layout'

const ErrorScreen = () => {
  return (
    <Layout>
      <Container>
        <SEO title="Fehler" />

        <h1>Uuups…</h1>

        <p>
          Ein Fehler ist passiert. Bitte versuchen Sie es später noch einmal.
        </p>

        {/* <ReactMarkdown source={texts['Text Fehler']} /> */}

        <ButtonLink onClick={() => navigate('/app')}>
          Zurück zur Startseite
        </ButtonLink>
      </Container>
    </Layout>
  )
}
export default ErrorScreen
