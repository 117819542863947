// @flow
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Button as ButtonGeneric } from '../UI'

import * as v from '../../config/variables'

const ActionButton = styled(ButtonGeneric)`
    display: inline-block;
    /* padding: 0.5rem 0.4rem; */
    padding: 0.625rem 0.3rem;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    line-height: 18px;
    font-weight: 600;
    white-space: normal;
    border: 1px solid transparent;
    border-radius: ${v.borderRadiusDefault};
    border-bottom-right-radius: ${props =>
      props.expanded ? 0 : v.borderRadiusDefault};
    transition-property: all;
    transition-timing-function: ease;
    transition-duration: 0.1s;
    color: ${({ editable }) =>
      editable ? v.white : transparentize(0.3, v.white)};
    background: linear-gradient(to top, ${v.brandColor}, hsl(198, 100%, 58%));
    background-size: 101% 200%;
    border-color: transparent;
    width: 103px;

  &:hover {
    color: ${({ editable }) =>
      editable ? v.white : transparentize(0.3, v.white)};

    &:not([disabled]) {
      border-color: ${v.brandColor};
      background-position: 0 100%;
    }
  }

  &:active {
    /* opacity: 0.5; */
  }

  &[disabled] {
    /* background-color: ${({ statusColor, status }) =>
      status !== 'done' ? transparentize(0.4, statusColor) : statusColor}; */
    /* color: ${({ status }) => (status === 'done' ? v.white : 'inherit')}; */
    background-color: transparent;
    color: ${v.gray500};
  }
`

export default ActionButton
