// @flow
import React from 'react'
// import { Link } from 'gatsby' // , graphql, useStaticQuery
import styled from 'styled-components'
// import ReactMarkdown from 'react-markdown'
import { Container } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import ActionCreators from '../store/actions'

// import { ButtonLink } from '../components/UI'
import TasksOverview from '../components/TasksOverview'
import Layout, { SEO } from '../components/Layout'

type Props = {
  objects: Object,
  status: Object,
}

const Wrap = styled.div`
  padding: 1rem 0 0;

  @media (min-width: 576px) {
    padding: 3rem 0 0;
  }
`

// const Hero = styled.div`
//   margin: 0 0 1.5rem;
//   padding: 1rem 0 0;

//   @media (min-width: 576px) {
//     margin: 0 0 3rem;
//   }

//   h1 {
//     font-size: 1.5rem;
//     margin: 0;

//     @media (min-width: 576px) {
//       font-size: 1.5rem;
//     }
//   }
// `

const TasksScreen = (props: Props) => {
  const { objects, status } = props
  const necessaryDataLoaded =
    objects.length > 0 && Object.keys(status).length > 0

  return (
    <Layout>
      <Container>
        <SEO title="Übersicht" />

        <Wrap>
          {/* <Hero>
          <h1>Aufgaben</h1>
        </Hero> */}

          {/* <p>
          Noch keine Aufgaben. (
          <Link to="/app/aufgabe/1">Beispiel Aufgabe-Detail-Seite</Link>)
        </p> */}
          {necessaryDataLoaded && <TasksOverview />}

          {/* <ReactMarkdown source={texts['Text Fehler']} /> */}

          {/* <ButtonLink onClick={() => navigate('/app')}>
          Zurück zur Startseite
        </ButtonLink> */}
        </Wrap>
      </Container>
    </Layout>
  )
}

function mapStateToProps({ objects, status }) {
  return { objects, status }
}

export default connect(mapStateToProps)(TasksScreen)
