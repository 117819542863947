// @flow
// import React from 'react'
import colors from 'material-colors/dist/colors.json'

export const getHexFromMaterialColor = (materialColorString: string) => {
  // const array = materialColorString.split('-')

  const splitted = [
    materialColorString.slice(0, materialColorString.lastIndexOf('-')),
    materialColorString.slice(materialColorString.lastIndexOf('-') + 1),
  ]
  return colors[splitted[0]][splitted[1]]
}

export const groupBy = (array, f) => {
  const groups = {}
  array.forEach(o => {
    const group = JSON.stringify(f(o))
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return Object.keys(groups).map(group => {
    return groups[group]
  })
}

export const getStatusFriendlyName = (status: string): string | null => {
  if (status === 'open') return 'Offen'
  if (status === 'in-progress') return 'In Bearbeitung'
  if (status === 'done') return 'Erledigt'
  if (status === 'inactive') return 'Inaktiv'

  return null
}

/**
 * Returns true if the given task is editable for the given user
 * @param {string | number} task The task object
 * @param {string | number} userId The user id
 */
export const taskIsEditable = (task: Object, userId: string | number = -1) => {
  const { status, bearbeiter: bearbeiterId } = task
  return (
    (status === 'open' &&
      (bearbeiterId === null || bearbeiterId === parseInt(userId, 10))) ||
    (status === 'in-progress' && bearbeiterId === parseInt(userId, 10)) // ||
    // (status === 'done' && bearbeiterId === parseInt(userId, 10)) // highlight own
  )
}
