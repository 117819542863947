// @flow
import React, { useState, useEffect } from 'react'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import { isController } from '../../lib/helper'

import { LoadingIndicator } from '../UI'
import TasksList from './TasksList'
import ListFilter from './ListFilter'
import Pagination from './Pagination'

type Props = {
  tasks: Object,
  status: Object,
  loadTasks: Function,
}

const TasksOverview = (props: Props) => {
  const {
    loadTasks,
    status,
    tasks: { entries, pagination },
  } = props
  const [loading, setLoading] = useState(false)
  const [loadingFinished, setLoadingFinished] = useState(false)
  const [page, setPage] = useState(1)
  // const [query, setQuery] = useState(null)
  // const [filterMyTasks, setFilterMyTasks] = useState(false)
  // const [sort, setSort] = useState('newest')
  const hasEntries = entries.length > 0

  const searchTasks = (
    query: string = '',
    filterMyTasks = false,
    filterCompletedTasks = isController()
  ) => {
    setLoading(true)
    return loadTasks(page, query, filterMyTasks, filterCompletedTasks)
      .then(() => {
        setLoading(false)
        setLoadingFinished(true)
      })
      .catch(() => {
        setLoading(false)
        setLoadingFinished(true)
      })
  }

  useEffect(() => {
    // alert('search Tasks, page: ', page)
    searchTasks()
  }, [])

  return (
    <>
      <ListFilter
        onChange={({
          query,
          filterMyTasks,
          filterCompletedTasks,
          // sort: currentSort,
        }) => {
          // setSort(currentSort)
          searchTasks(query, filterMyTasks, filterCompletedTasks)
        }}
        // onSubmit={searchTasks}
        loading={loading}
      />

      <LoadingIndicator loading={loading} />

      {!loading && hasEntries && (
        <TasksList tasks={entries} statusOptions={status} />
      )}

      {!loading && !hasEntries && loadingFinished && (
        <p>
          <strong>Für Ihre Suche wurden keine Ergebnisse gefunden.</strong>
        </p>
      )}

      {hasEntries && (
        <Pagination
          pagination={pagination}
          onChange={({ selected: currentPage }) => {
            setPage(currentPage + 1)
          }}
        />
      )}
    </>
  )
}

function mapStateToProps({ tasks, status }) {
  return { tasks, status }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksOverview)
