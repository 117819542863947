// @flow
import React, { useEffect } from 'react'
import { Row, Col, Collapse } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import * as v from '../../config/variables'
import { getStatusFriendlyName } from '../../lib/utils'

import { EmptyResultText } from '../UI/Typography'
import ComposeNote from './ComposeNote'
import Remarks from './Remarks'
import { isGuest } from '../../lib/helper'

type Props = {
  expanded: boolean,
  task: Object,
  userHasEditorLevel: boolean,
  loadUserInfo: Function,
}

const StyledTaskExtendedContent = styled.div`
  border-top: 1px solid ${v.grayLight};
  padding: 0.5rem 1rem 1rem;
  font-size: 0.9rem;
  background-color: ${v.white};
  border-radius: 0 0 ${v.borderRadiusDefault} ${v.borderRadiusDefault};
`

const Block = styled.div`
  p {
    font-size: 0.8rem;

    @media (min-width: 576px) {
      /* font-size: 1rem; */
    }
  }
`

const BlockHeadline = styled.h4`
  margin: 1rem 0 0.2rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${v.gray500};

  @media (min-width: 576px) {
    /* font-size: 1rem; */
  }
`

const AssigneeInfo = styled.aside`
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
`

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${v.gray300};
  margin: 0 0.5rem 0 0;
`

const TaskExtendedContent = (props: Props) => {
  // const [loading, setLoading] = useState(false)
  const { expanded, task, userHasEditorLevel, loadUserInfo } = props
  const {
    objekt,
    lage,
    position,
    status,
    bemerkungen,
    erstellungsdatum: createdOn,
    id: taskId,
    bearbeiter: bearbeiterId,
    assigneeInfo,
  } = task
  const assigneeFullName =
    (assigneeInfo || {}).first_name && (assigneeInfo || {}).last_name
      ? `${(assigneeInfo || {}).first_name || ''} ${(assigneeInfo || {})
          .last_name || ''}`
      : null
  const assigneeAvatar =
    (
      ((((assigneeInfo || {}).avatar || {}).data || {}).thumbnails || {})[0] ||
      {}
    ).url || null
  const friendlyCreatedOnDate = moment(createdOn).format('LLL')

  useEffect(() => {
    if (userHasEditorLevel && bearbeiterId) {
      // setLoading(true)
      loadUserInfo(bearbeiterId, taskId).then(() => {
        // setLoading(false)
      })
    }
  }, [])

  return (
    <Collapse isOpen={expanded}>
      <StyledTaskExtendedContent>
        <Row>
          <Col sm="6">
            <Row>
              <Col xs="6" sm="12">
                <Block>
                  <BlockHeadline>Status</BlockHeadline>
                  <p>{getStatusFriendlyName(status)}</p>
                </Block>
              </Col>

              {objekt && (
                <Col xs="6">
                  <Block>
                    <BlockHeadline>Objekt</BlockHeadline>
                    <p>{objekt.name}</p>
                  </Block>
                </Col>
              )}

              {lage && (
                <Col xs="6">
                  <Block>
                    <BlockHeadline>Lage</BlockHeadline>
                    <p>{lage}</p>
                  </Block>
                </Col>
              )}

              {position && (
                <Col xs="6">
                  <Block>
                    <BlockHeadline>Position</BlockHeadline>
                    <p>{position}</p>
                  </Block>
                </Col>
              )}

              <Col xs="6" sm="12">
                <Block>
                  <BlockHeadline>Erstellt am</BlockHeadline>
                  <p>{friendlyCreatedOnDate}</p>
                </Block>
              </Col>
            </Row>

            {userHasEditorLevel && (
              <Block>
                <BlockHeadline>Bearbeiter</BlockHeadline>

                {!bearbeiterId && (
                  <EmptyResultText>Noch kein Bearbeiter</EmptyResultText>
                )}

                <AssigneeInfo>
                  {assigneeAvatar && (
                    <Avatar
                      src={assigneeAvatar}
                      alt={assigneeFullName || 'Avatar'}
                    />
                  )}

                  {bearbeiterId && <p>{assigneeFullName}</p>}
                </AssigneeInfo>
              </Block>
            )}
          </Col>

          <Col sm="6">
            <BlockHeadline>Bemerkungen</BlockHeadline>

            <Remarks data={bemerkungen} />

            {!isGuest() && <ComposeNote taskId={taskId} />}
          </Col>
        </Row>
      </StyledTaskExtendedContent>
    </Collapse>
  )
}

function mapStateToProps({ user, userPermissions }) {
  return { user, userPermissions }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskExtendedContent)
