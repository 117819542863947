// @flow
import React, { useState } from 'react'
import styled from 'styled-components'
import Textarea from 'react-textarea-autosize'
import { lighten } from 'polished'
// import { Row, Col } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import * as v from '../../config/variables'
import { Button as ButtonGeneric } from '../UI'

type Props = {
  taskId: string,
  addRemark: Function,
}

const StyledMessage = styled.div`
  margin-top: 0.5rem;
`

const MessageBox = styled(Textarea)`
  display: block;
  line-height: 1.5rem;
  width: 100%;
  resize: none;
  border: 1px solid ${v.gray};
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
  outline: none;
  padding: 0.5rem 0.7rem 3.6rem;
  font-size: 0.8rem;
  /* margin: 0 0 1rem; */

  &:focus {
    box-shadow: 0 0 0 4px ${lighten(0.3, v.brandColor)};
  }
`

const Button = styled(ButtonGeneric)`
  float: right;
  margin-top: -56px;
  margin-right: 0.5rem;
`

const Message = (props: Props) => {
  const { taskId, addRemark } = props
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)
  const [error, setError] = useState(false)

  const submitMessage = () => {
    setLoading(true)
    addRemark(taskId, message)
      .then(() => {
        setLoading(false)
        setFinishedLoading(true)
        setMessage('')
      })
      .catch(() => {
        setError(true)
        setFinishedLoading(true)
        setLoading(false)
      })
  }

  return (
    <StyledMessage>
      {/* <div style={{ textAlign: 'center' }}>
        <h2 style={{ marginTop: 0 }}>
          Du möchtest dem Verfasser eine Nachricht senden? Lorem ipsum…        </h2>
        <p>
          Aliquip aliqua sit nisi consequat adipisicing velit officia irure
          culpa elit dolor pariatur.
        </p>
      </div> */}

      {/* <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}> */}
      <MessageBox
        minRows={2}
        placeholder="Schreibe eine Nachricht…"
        value={message}
        onChange={e => {
          setMessage(e.target.value)
        }}
        maxLength={1000}
      />

      {message.length > 0 && (
        <Button
          type="submit"
          onClick={() => submitMessage()}
          disabled={loading}
        >
          {loading ? 'Wird gesendet…' : 'Senden '}
        </Button>
      )}

      {/* {finishedLoading && !error && (
        <>
          <h2>Vielen Dank</h2>
          <p>Ihre Bemerkung wurde gespeichert.</p>
        </>
      )} */}

      {finishedLoading && error && (
        <>
          <h2>Uuups…</h2>
          <p>
            Ein Fehler ist passiert. Bitte versuchen Sie es später noch einmal.
          </p>
        </>
      )}
      {/* </Col>
      </Row> */}
    </StyledMessage>
  )
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Message)
