// @flow
import React from 'react'

import styled from 'styled-components'
import TasksListItem from './TasksListItem'

import * as v from '../../config/variables'
import { groupBy } from '../../lib/utils'
import Statistics from './Statistics'

type Props = {
  tasks: Array<Object>,
  statusOptions: Object,
}

const StyledTasksList = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
`

const TaskGroup = styled.div`
  /* list-style-type: none;
  padding: 0; */
  margin: 0 0 2rem;
`

const TaskGroupHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0.75rem;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const GroupTitle = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  color: ${v.orange};
  margin: 0 0 0.25rem;

  @media (min-width: 576px) {
    margin: 0;
  }
`

const TasksList = (props: Props) => {
  const { tasks, statusOptions } = props

  // Group and sort Tasks
  const ordering = {}
  const order = ['in-progress', 'open', 'done', 'inactive']
  order.map((s, i) => {
    ordering[order[i]] = i
    return null
  })

  const groupedTasks = groupBy(
    tasks.filter(t => t.objekt), // object has to be set
    task => task.objekt.id
  )
    .sort((a, b) => {
      return a[0].objekt.sortierung - b[0].objekt.sortierung
    })
    .map(g =>
      g.sort(
        (a, b) =>
          ordering[a.status] - ordering[b.status] ||
          (a.lage || '').localeCompare(b.lage || '') ||
          a.bezeichnung.localeCompare(b.bezeichnung)
      )
    )

  if (!tasks.length) return null

  return (
    <StyledTasksList>
      {groupedTasks.map((taskGroup: Array<Object>) => {
        const object = taskGroup[0].objekt || null

        return (
          <TaskGroup key={object.id}>
            <TaskGroupHeader>
              <GroupTitle>{taskGroup[0].objekt.name}</GroupTitle>

              <Statistics taskGroup={taskGroup} />
            </TaskGroupHeader>

            {taskGroup.map(rawTask => {
              const task = {
                ...rawTask,
                statusColor: statusOptions[rawTask.status].background_color,
              }

              const { id } = task
              return <TasksListItem key={id} task={task} />
            })}
          </TaskGroup>
        )
      })}
    </StyledTasksList>
  )
}

export default TasksList
