// @flow
import React from 'react'
import styled from 'styled-components'
import TurndownService from 'turndown'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'

import { isMe } from '../../lib/helper'
import * as v from '../../config/variables'
import { EmptyResultText } from '../UI/Typography'

const turndownService = new TurndownService()

type Props = {
  data: Array<Object>,
}

const NotesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  /* max-height: 600px;
  overflow-y: auto; */
`

const Note = styled.li`
  align-self: ${props => (props.isMine ? 'flex-end' : 'flex-start')};
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isMine ? 'flex-end' : 'flex-start')};

  &:not(:last-of-type) {
    margin: 0 0 1rem;
  }
`

const Message = styled.div`
  color: ${props => (props.isMine ? v.white : v.textColor)};
  background-color: ${props => (props.isMine ? v.brandColor : v.gray300)};
  border-radius: 20px;
  padding: 0.25rem 0.7rem 0.25rem 0.7rem;
  font-size: 0.8rem;
  line-height: 1.4;

  p {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.3rem;
`

// const Author = styled.span`
//   font-weight: bold;
//   font-size: 0.6rem;
//   margin-right: 0.25rem;
//   color: ${v.gray600};
// `

const Date = styled.span`
  display: block;
  color: ${v.gray600};
  font-size: 0.6rem;
`

const Remarks = (props: Props) => {
  const { data: bemerkungen } = props

  if (!bemerkungen || !(bemerkungen.length > 0)) {
    return <EmptyResultText>Noch keine Bemerkungen.</EmptyResultText>
  }

  return (
    <NotesList>
      {bemerkungen.map(bemerkung => {
        const { text, id, erstelldatum, erstellt_von: author } = bemerkung
        const { id: authorId } = author
        const dateCreated = moment(erstelldatum)
        const friendlyDate =
          dateCreated > moment().subtract(5, 'minutes')
            ? dateCreated.fromNow()
            : dateCreated.format('LLL')
        // maybe make this checking safer later
        const isMine = isMe(author) || isMe(authorId)

        return (
          <Note key={id} isMine={isMine}>
            <Message isMine={isMine}>
              <ReactMarkdown source={turndownService.turndown(text)} />
            </Message>

            <Meta>
              {/* {isMine && <Author>Ich</Author>} */}

              <Date>{friendlyDate}</Date>
            </Meta>
          </Note>
        )
      })}
    </NotesList>
  )
}

export default Remarks
