// @flow
import React from 'react'
import { navigate } from 'gatsby' // , graphql, useStaticQuery
// import ReactMarkdown from 'react-markdown'

import { Container } from 'reactstrap'

import { ButtonLink } from '../components/UI'
import Layout, { SEO } from '../components/Layout'

type Props = {
  taskId: string,
}

const TaskDetailScreen = (props: Props) => {
  const { taskId } = props

  return (
    <Layout>
      <Container>
        <SEO title="Fehler" />

        <h1>Aufgabe Detail</h1>

        <p>Aufgaben-ID: {taskId}</p>
        {/* <ReactMarkdown source={texts['Text Fehler']} /> */}

        <ButtonLink onClick={() => navigate('/app')}>
          Zurück zur Startseite
        </ButtonLink>
      </Container>
    </Layout>
  )
}
export default TaskDetailScreen
