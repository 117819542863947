// @flow
import React from 'react'
import styled from 'styled-components'
import { Progress as ProgressGeneric } from 'reactstrap'
import { transparentize } from 'polished'

import * as v from '../../config/variables'
import { isController } from '../../lib/helper'

type Props = { taskGroup: Object }

const StyledProgress = styled(ProgressGeneric)`
  /* outline: 1px solid red; */
  border-radius: ${v.borderRadiusDefault};
  width: 200px;
  height: 0.25rem;
  box-shadow: inset 0 0 0 1px ${v.gray500};

  @media (min-width: 576px) {
    height: 7px;
  }
`

const Progress = styled(ProgressGeneric)`
  background-color: ${({ statusColor }) =>
    transparentize(0.4, statusColor) || v.gray700};
`

const relativePercentage = (count, total) => Math.round((count / total) * 100)

const Statistics = (props: Props) => {
  const { taskGroup } = props
  const total = taskGroup.length
  const open = relativePercentage(
    taskGroup.filter(t => t.status === 'open').length,
    total
  )
  const inProgress = relativePercentage(
    taskGroup.filter(t => t.status === 'in-progress').length,
    total
  )
  const completed = relativePercentage(
    taskGroup.filter(t => t.status === 'done').length,
    total
  )
  const inactive = relativePercentage(
    taskGroup.filter(t => t.status === 'inactive').length,
    total
  )

  if (isController() && completed === 100) return null

  return (
    <StyledProgress multi>
      <Progress bar statusColor={v.materialColors('red-700')} value={open} />
      <Progress
        bar
        statusColor={v.materialColors('yellow-700')}
        value={inProgress}
      />
      <Progress
        bar
        statusColor={v.materialColors('green-700')}
        value={completed}
      />
      <Progress
        bar
        statusColor={v.materialColors('blue-grey-200')}
        value={inactive}
      />
    </StyledProgress>
  )
}

export default Statistics
