// @flow
import React from 'react'
import { navigate } from 'gatsby'
// eslint-disable-next-line
import { Router, Redirect } from '@reach/router'

import PrivateRoute from '../components/Routing'
import TasksScreen from './TasksScreen'
import TaskDetailScreen from './TaskDetailScreen'
// import VerfassenScreen from './VerfassenScreen'
// import VerfassenSuccessScreen from './VerfassenSuccessScreen'
import ErrorScreen from './ErrorScreen'

import { loggedIn } from '../lib/helper'

type Props = {
  location: Object,
}

const NotFound = () => {
  navigate('/404', { replace: true })
  return null
}

const App = ({ location }: Props) => {
  const { pathname } = location
  const redirectionUrl = pathname ? `/login?returnUrl=${pathname}` : '/login'

  if (!loggedIn()) return <Redirect to={redirectionUrl} noThrow />

  if (pathname === '/app' || pathname === '/app/')
    return <Redirect to="/app/aufgaben" noThrow />

  return (
    <Router>
      {/* <Redirect from="/app" to="/app/overview" noThrow /> */}

      <PrivateRoute path="/app/aufgaben" component={TasksScreen} />
      <PrivateRoute path="/app/aufgabe/:taskId" component={TaskDetailScreen} />
      {/* 
      <PrivateRoute path="/app/verfassen" component={VerfassenScreen} />
      <PrivateRoute
        path="/app/verfassen/erfolg"
        component={VerfassenSuccessScreen}
      /> */}
      <PrivateRoute path="/app/error" component={ErrorScreen} />

      <NotFound default />
    </Router>
  )
}

export default App
