// @flow
import React, { useState } from 'react'
// import { Link as LinkGeneric } from 'gatsby'
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import { KeyboardArrowDown } from '@material-ui/icons'
import styled from 'styled-components'
import { transparentize, lighten } from 'polished'
// import LinesEllipsis from 'react-lines-ellipsis'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import { userHasEditorLevel, isGuest, isController } from '../../lib/helper'
import { taskIsEditable } from '../../lib/utils'
import * as v from '../../config/variables'
import TaskExtendedContent from './TaskExtendedContent'
import ActionButton from './ActionButton'

type Props = {
  task: Object,
  user: Object,
  setTaskProgress: Function,
  setTaskAsControlled: Function,
}

// const Status = styled.span`
//   display: inline-block;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   margin: 0 0.5rem 0 0;
//   background-color: ${({ statusColor }) => statusColor || 'black'};
// `

const HeadlineTask = styled.h2`
  margin: 0 !important;
  font-size: 0.8rem;
  line-height: 1.2;
  word-break: break-word;

  @media (min-width: 675px) {
    font-size: 0.9rem;
  }
`

const MainCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin: 0 0 0.5rem !important; */
`
const SecondaryCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
`

const SubGroup = styled.div`
  display: flex;
  align-items: center !important;
`

const Inner = styled.div`
  /* overflow: hidden; */
  color: ${v.textColor};
  background-color: ${v.gray100};
  padding: 0;
  border-radius: ${v.borderRadiusDefault};
  /* transition: box-shadow ease 0.3s; */
  border: 1px solid ${lighten(0.1, v.gray500)};
  transition: all 0.2s ease;

  /* &:hover, */
  &:focus {
    box-shadow: none;
  }
`

const StyledTasksListItem = styled.li`
  &:hover {
    > ${Inner} {
      border-color: ${v.gray500};
      transform: translateY(-2px);
      box-shadow: 0 3px 3px 0 rgba(0,0,0,0.08);
      /* box-shadow: 0 0 0 1px ${v.gray}; */
    }
  }
`

const MainContent = styled.div`
  color: ${v.white};
  display: flex;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;
  height: 78px;

  @media (min-width: 992px) {
    height: 69px;
  }
  /* justify-content:  */
`

const Content = styled.div`
  flex: 1 1 auto;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;

  position: relative;

  &:before {
    content: '';
    box-sizing: content-box;
    width: 0.5rem;
    background-color: ${({ statusColor }) =>
      transparentize(0.4, statusColor) || v.gray400};
    border: 1px solid
      ${({ statusColor }) => transparentize(0.05, statusColor) || v.gray400};
    height: 100%;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: ${v.borderRadiusDefault} 0 0
      ${({ expanded }) => (expanded ? 0 : v.borderRadiusDefault)};
  }
`

const ActionButtons = styled.div`
  flex: 0 0 auto;
  padding: 0;
  display: flex;
  height: 100%;

  > ${ActionButton} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    top: -1px;
    right: -1px;
    height: calc(100% + 2px);
  }
`

const Location = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${v.textColor};
  line-height: 1;
  white-space: pre;
`

const Toggle = styled.div`
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  flex: 0 0 auto;
  align-items: center;
  justify-content: ${({ mobile }) => (mobile ? 'flex-end' : 'center')};
  padding: 0 0.5rem;
  width: 38px;
  height: ${({ mobile }) => (mobile ? '17px' : 'auto')};

  @media (min-width: 675px) {
    display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  }
`

const Caret = styled(KeyboardArrowDown)`
  color: ${v.gray600};
  font-size: 4rem;
  transform: rotate(${({ expanded }) => (!expanded ? '0deg' : '180deg')});
`

const Label = styled.span`
  background: ${v.brandColor};
  display: inline-block;
  margin: 0.1rem 0;
  border-radius: ${v.borderRadiusDefault};
  line-height: 1;
  padding: 0.15rem 0.25rem;
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${v.yellow};
  border: 2px dashed ${v.yellow};
  /* border-image: url(${require('../../images/border-dashed.png')});
  border-image-slice: 2;
  border-image-repeat: round; */
  background-color: transparent;
  /* ${transparentize(0.4, v.yellow)}; */
  
  /* @media (min-width: 576px) {
    margin: 0.1rem 0.3rem;
  } */

  /* position: relative; */

  /* &:after {
    content: '✶';
    z-index: 1;
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    border-radius: 50%;
    font-size: 0.5rem;
    color: ${v.yellow};
    padding: 1px 1px;
    background-color: ${v.gray100};
  } */
`

const HeadlineText = styled.span`
  /* display: inline-block; */
  margin-right: ${({ isNew }) => (isNew ? '0.5rem' : '0')};
`

const TasksListItem = (props: Props) => {
  const { task, user, setTaskProgress, setTaskAsControlled } = props
  const { id: userId } = user
  const {
    id,
    bezeichnung: title,
    erstellungsdatum: date,
    status,
    statusColor,
    lage: location,
    kontrolliert,
  } = task
  const [expanded, setExpanded] = useState(false)
  const [updating, setUpdating] = useState(false)
  const onCreatedDate = moment(date)
  const isNew = onCreatedDate > moment().subtract('1', 'days')
  // const friendlyTime = now > moment().subtract(10, 'days') ? now.fromNow() : now.format('LL')

  const isEditable = taskIsEditable(task, userId) && !isController()
  const isControllable = isController() && status === 'done'

  const startProgress = () => {
    if (
      typeof window !== 'undefined' &&
      // eslint-disable-next-line
      window.confirm('Möchten Sie diese Aufgabe wirklich starten?')
    ) {
      setUpdating(true)
      setTaskProgress(id, 'in-progress').then(() => {
        setUpdating(false)
      })
    }
  }

  const completeTask = () => {
    if (
      typeof window !== 'undefined' &&
      // eslint-disable-next-line
      window.confirm('Ist diese Aufgaben wirklich erledigt?')
    ) {
      setUpdating(true)
      setTaskProgress(id, 'done').then(() => {
        setUpdating(false)
      })
    }
  }

  const markTaskAsControlled = () => {
    if (
      typeof window !== 'undefined' &&
      // eslint-disable-next-line
      window.confirm(
        'Möchten Sie diese Aufgabe wirklich als kontrolliert markieren?'
      )
    ) {
      setUpdating(true)
      setTaskAsControlled(id).then(() => {
        setUpdating(false)
      })
    }
  }

  const handleProgress = () => {
    if (isGuest())
      // eslint-disable-next-line
      return alert('Als Gast können Sie keine Aktionen durchführen.')

    if (status === 'open') startProgress()
    if (status === 'in-progress') completeTask()
  }

  return (
    <StyledTasksListItem>
      <Inner>
        <MainContent
          title={`${title} – erstellt am ${onCreatedDate.format('LL')}`}
        >
          {/* <Indicator /> */}

          <Content
            onClick={() => setExpanded(!expanded)}
            statusColor={statusColor}
            expanded={expanded}
          >
            <Row style={{ flex: '1 0 auto' }}>
              <MainCol>
                <SubGroup>
                  {/* <Status statusColor={statusColor} title={status} /> */}
                  <HeadlineTask as="h3">
                    <HeadlineText isNew={isNew}>{title}</HeadlineText>
                    {isNew && <Label>Neu</Label>}
                  </HeadlineTask>
                </SubGroup>

                {/* <SubGroup>
                  
                </SubGroup> */}
              </MainCol>
            </Row>

            <Row>
              <SecondaryCol>
                {/* <Date>{friendlyTime}</Date> */}
                <Location>{location}</Location>
              </SecondaryCol>

              <Toggle onClick={() => setExpanded(!expanded)} mobile>
                <Caret expanded={expanded ? 1 : 0} fontSize="large" />
              </Toggle>
            </Row>
          </Content>

          <Toggle onClick={() => setExpanded(!expanded)}>
            <Caret expanded={expanded ? 1 : 0} fontSize="large" />
          </Toggle>

          <ActionButtons>
            {isControllable && !kontrolliert && (
              <ActionButton
                statusColor={statusColor}
                status={status}
                disabled={updating}
                editable={isControllable}
                onClick={markTaskAsControlled}
                expanded={expanded}
              >
                {kontrolliert ? 'Kontrolliert' : 'Prüfung abschließen'}
                {/* {status === 'done' && 'Inaktiv'} */}
              </ActionButton>
            )}

            {isEditable && (
              <ActionButton
                statusColor={statusColor}
                status={status}
                disabled={!isEditable || updating}
                editable={isEditable}
                onClick={handleProgress}
                expanded={expanded}
              >
                {status === 'open' && isEditable && 'Start'}
                {status === 'open' && !isEditable && 'Bereits zugewiesen'}
                {status === 'in-progress' && !isEditable && 'Wird bearbeitet'}
                {status === 'in-progress' && isEditable && 'Beenden'}
                {status === 'done' && 'Erledigt'}
                {status === 'inactive' && 'Inaktiv'}
              </ActionButton>
            )}
          </ActionButtons>
        </MainContent>

        {expanded && (
          <TaskExtendedContent
            expanded={expanded}
            task={task}
            userHasEditorLevel={userHasEditorLevel()}
          />
        )}
      </Inner>
    </StyledTasksListItem>
  )
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksListItem)
